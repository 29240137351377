<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <div class="d-flex flex-row flex-grow-1 justify-space-between">
          <h3
            class="font-weight-bold blue-grey--text mt-2 font font-md font-weight-medium text-uppercase"
          >
            All Transactions
          </h3>
          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            :return-value.sync="dates"
            transition="scale-transition"
            offset-y
            class="font font-weight-medium"
            min-width="auto"
            :nudge-left="120"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-chip
                v-model="dates"
                v-bind="attrs"
                v-on="on"
                label
                :close="dates.length === 2"
                @click:close="(dates = []), (chosenDateRange = [])"
                :color="dates.length > 0 ? 'primary' : 'white'"
                class=" box-shadow-light mt-2 font text-uppercase font-weight-medium"
              >
                <i class="material-icons-outlined font-size-md mr-2">event</i>
                {{ dates.length > 0 ? dateRangeText : "Choose date range" }}
              </v-chip>
            </template>
            <v-date-picker
              class="font font-weight-medium font-size-sm"
              v-model="dates"
              range
            >
              <v-spacer></v-spacer>
              <v-btn
                small
                class="font font-weight-medium"
                text
                color="primary"
                @click="(menu = false), (date = []), (chosenDateRange = [])"
              >
                Cancel
              </v-btn>
              <v-btn
                @click="setFilteredDate"
                text
                class="font font-weight-medium"
                color="primary"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
        </div>
      </v-col>
      <v-col cols="12" sm="12">
        <div class="d-flex flex-row flex-grow-1 justify-end">
          <v-text-field
            solo
            rounded
            placeholder="Search through transactions using account number ..."
            flat
            append-icon="search"
            v-model="search"
            hide-details
            class="box-shadow-light font font-sm"
            style="min-width: 400px"
          >
          </v-text-field>
          <v-tooltip right>
            <template v-slot:activator="{ on }">
              <v-btn
                :loading="pageLoading"
                class="mx-2 mt-1"
                @click="$store.dispatch('transactions/list', '?page=1&size=30')"
                v-on="on"
                small
                fab
                icon
              >
                <i class="material-icons-outlined">refresh</i>
              </v-btn>
            </template>
            <span class=" font font-sm">Get latest transactions</span>
          </v-tooltip>
        </div>
      </v-col>
      <v-col cols="12" sm="12" lg="12" md="12" xl="12">
        <v-data-table
          :headers="headers"
          :items="transactions"
          :loading="pageLoading"
          item-key="branch"
          :server-items-length="paginate.total"
          :options.sync="options"
          class="font text-capitalize box-shadow-light mx-1 mt-n2"
          loading-text="Loading... Please wait"
          :footer-props="{
            itemsPerPageOptions: [30, 40, 50]
          }"
        >
          <template #item.meta="{item}">
            <v-chip label small>
              <span
                v-if="item.meta && item.meta.accountName"
                class=" font-sm font-weight-medium"
                >{{ item.meta.accountName }}
              </span>
              <span v-else class="font font-weight-medium font-sm">
                N/A
              </span>
            </v-chip>
          </template>
          <template #item.totalAmount="{item}">
            <span class="font-sm font-weight-bold"
              >GHC {{ item.totalAmount ? item.totalAmount : "N/A" }}
            </span>
          </template>
          <template #item.service="{item}">
            <v-chip label small color="primary">
              <span class=" font text-uppercase font-sm font-weight-medium">
                {{ item.meta.product ? item.meta.product : item.meta.name }}
              </span>
            </v-chip>
          </template>
          <template #item.paymentStatus="{item}">
            <v-chip
              v-if="
                item.paymentStatus &&
                  item.paymentStatus.toLowerCase() === 'success'
              "
              label
              small
              color="success"
            >
              <span class=" font font-weight-medium font-sm text-uppercase">{{
                item.paymentStatus
              }}</span>
            </v-chip>
            <v-chip
              color="red"
              v-else-if="
                item.paymentStatus &&
                  item.paymentStatus.toLowerCase() === 'failed'
              "
              label
              small
            >
              <span
                class=" white--text font font-weight-medium font-sm text-uppercase"
                >{{ item.paymentStatus }}</span
              >
            </v-chip>
            <v-chip v-else label small color="orange">
              <span
                class=" white--text font font-weight-medium font-sm text-uppercase"
                >{{ "N/A" }}</span
              >
            </v-chip>
          </template>
          <template #item.createdAt="{item}">
            <span class=" font font-weight-medium font-sm">
              {{ item.createdAt | dateFormat }}
            </span>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
export default {
  name: "Transactions",

  data() {
    return {
      search: "",
      options: {
        page: 1,
        itemsPerPage: 30
      },
      menu: false,
      dates: [],
      chosenDateRange: []
    };
  },

  computed: {
    ...mapGetters({
      pageLoading: "getIsPageLoading",
      paginate: "transactions/getPagination",
      transactions: "transactions/getAllTransactions"
    }),
    dateRangeText() {
      return this.dates.join(" ~ ");
    },
    transactionsList() {
      return this.transactions.filter(searchTerm => {
        return (
          searchTerm.sequenceID
            .toLowerCase()
            .indexOf(this.search.toLowerCase()) !== -1
        );
      });
    },

    headers() {
      return [
        {
          text: "Date",
          value: "createdAt",
          filterable: false,
          sortable: false
        },
        {
          text: "Ref#",
          value: "reference",
          filterable: false,
          sortable: false
        },
        {
          text: "Acc. Number",
          value: "accountNumber",
          filterable: true,
          sortable: false
        },
        {
          text: "Acc. Name",
          value: "meta",
          filterable: true,
          sortable: false
        },
        {
          text: "Amount (GHS)",
          value: "totalAmount",
          filterable: true,
          sortable: false
        },
        {
          text: "Service",
          value: "service",
          sortable: false
        },
        {
          text: "Status",
          value: "paymentStatus",
          sortable: false
        }
      ];
    }
  },
  filters: {
    status(value) {
      return !value ? "Active" : "Inactive";
    },
    firstLetterFunction(value) {
      return value.charAt(0).toUpperCase();
    },
    dateFormat(value) {
      return moment(value).format("Do MMM,YYYY HH:mm:ss");
    }
  },

  watch: {
    search(searchTerm) {
      if (searchTerm !== "") {
        const query = `?searchTerm=${searchTerm}&page=1&size=30`;
        this.$store.dispatch("transactions/searchList", query);
      } else {
        this.$store.dispatch("transactions/list", "?page=1&size=30");
      }
    },
    options: {
      handler(value) {
        const { page, itemsPerPage } = value;
        const query = `?page=${page}&size=${itemsPerPage}`;
        this.$store.dispatch("transactions/list", query);
      },
      deep: true
    },
    chosenDateRange(dates) {
      if (dates.length > 1) {
        const [startDate, endDate] = dates;
        const query = `?page=1&size=30&startDate=${startDate}&endDate=${endDate}`;
        this.$store.dispatch("transactions/list", query);
      } else {
        this.$store.dispatch("transactions/list", "?page=1&size=30");
      }
    }
  },

  methods: {
    setFilteredDate() {
      this.$refs.menu.save(this.dates);
      this.chosenDateRange = this.dates;
    }
  },
  created() {
    const query = `?page=1&size=30`;
    this.$store.dispatch("transactions/list", query);
  }
};
</script>

<style scoped>
.createNew {
  display: flex;
  flex-direction: row;
  wrap: nowrap;
  justify-content: space-between;
  align-items: stretch;
  alignt-content: stretch;
}

.box {
  height: auto;
  width: auto;
  padding: 5%;
}
</style>
